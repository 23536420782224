<template>
  <div class="home">
    <div class="container">
      <v-header/>
    </div>
    <div class="section dt12 db7 mt7 mb7">
      <div class="container">
        <div class="row middle">
          <div class="d-7">
            <h1 class="db9">Ой!</h1>
            <p class="h4"><strong>Что-то пошло не&nbsp;так, и&nbsp;мы&nbsp;это уже чиним</strong></p>
            <p>Приносим извинения за&nbsp;предоставленные неудобства и&nbsp;предлагаем:</p>
            <ul>
              <li>перейти на&nbsp;<a href="/">главную страницу</a> сайта;</li>
              <li>обратиться с&nbsp;интересующим вас вопросом в&nbsp;поддержку сервиса или написать нам на&nbsp;<a href="mailto:team@ediweb.com">team@ediweb.com</a></li>
            </ul>
          </div>
          <div class="d-4 d-offset-1 m-hide middle">
            <img src="@/assets/error.svg"
              data-copyright-source="https://storyset.com/illustration/503-error-service-unavailable/cuate"
              data-copyright-name="Storyset"
              data-copyright-owner="Storyset"
              data-copyright-profile="https://storyset.com/"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const meta = {
  title: "Что-то пошло не так, и мы это уже чиним | WorkCat",
};

export default {
  name: 'Error',
  components: {
    'v-header': () => import('@/components/v-header.vue'),
  },
  metaInfo: {
    title: meta.title,
    meta: [
      {
        name: "og:title",
        content: meta.title,
      },
      {
        name: "robots",
        content: "none",
      },
    ],
  },
}
</script>
